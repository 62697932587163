import React, { useState, useEffect } from "react"
import moment from "moment"
import { PayPalButton } from "react-paypal-button-v2"
import { Link } from "react-router-dom"
import Schedule from "../components/Schedule"
import Times from "../components/Times"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Card, Modal, Button } from "react-bootstrap"
import Loader from "../components/Loader"
import Message from "../components/Message"
import {
  listSubactivityDetails,
  listSubActivities,
} from "../actions/productActions"
var CurrencyFormat = require("react-currency-format")

const SubActivityScreen = ({ location, match }) => {
  const [date, setDate] = useState("")
  const [time, setTime] = useState("")
  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [notes, setNotes] = useState("")
  const [numOfPersons, setNumOfPersons] = useState(0)
  const [showPaypalComplete, setShowPaypalComplete] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [show, setShow] = useState(false)
  const handleClosePaypalComplete = () => setShowPaypalComplete(false)
  const handleShowPaypalComplete = () => setShowPaypalComplete(true)
  const server = "https://transportation-backend-test.link"

  const { subActivityPrice, subActivityname, subActivityimage, subActivityId } =
    location.state

  function toPayHandler(event) {
    event.preventDefault()
    handleShow()
  }

  const getLimitNumOfPassengers = (transportationNameSelected, productName) => {
    var numberOfPassengers = []
    if (productName === "Shuttle") {
      numberOfPassengers = transportationNameSelected.split(" ")
      return numberOfPassengers[1]
    } else {
      numberOfPassengers = transportationNameSelected.split("-")
      if (numberOfPassengers.length === 1) {
        numberOfPassengers = transportationNameSelected.split(" ")
        return numberOfPassengers[1]
      } else {
        return numberOfPassengers[1]
      }
    }
  }

  const limitNumOfPassengers = getLimitNumOfPassengers(
    subActivityname
    //productName
  )

  /*const dispatch = useDispatch()

  const subactivityDetails = useSelector((state) => state.subactivityDetails)
  const { loading, error, product } = subactivityDetails

  let productName = product.name
  useEffect(() => {
    dispatch(listSubactivityDetails(match.params.id))
    dispatch(listSubActivities())
  }, [dispatch, match])*/

  return (
    <>
      <Link className='btn btn-light my-3' to={`/activity/${match.params.id}`}>
        Go Back
      </Link>
      <Container fluid>
        <Row className='justify-content-md-center'>
          <Col xs={12} sm={4} md={4} lg={4}>
            <Card className='text-center'>
              <Card.Img
                variant='top'
                src={subActivityimage}
                alt={subActivityname}
              />
              <Card.Body>
                <Card.Title as='h4'>
                  <strong>{subActivityname}</strong>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs={12} sm={4} md={4} lg={4}>
            <Card className='text-center'>
              <Card.Body>
                <Schedule proyect={1} subActivityId={subActivityId} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs={12} sm={4} md={4} lg={4}>
            <Card className='text-center'>
              <Card.Body>
                <Times />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='text-center'>
          <Col sm={12}>
            <Form onSubmit={toPayHandler}>
              <Form.Row className='justify-content-center m-2'>
                <Col lg={2}>
                  <Form.Label>Number Of Persons:</Form.Label>
                  <Form.Control
                    type='number'
                    value={numOfPersons}
                    onChange={(e) => setNumOfPersons(e.target.value)}
                    min='1'
                    max={limitNumOfPassengers}
                    required
                  />
                </Col>
              </Form.Row>
              <Form.Row className='justify-content-center m-2'>
                <Col lg={4} className='m-1'>
                  <Form.Control
                    type='text'
                    size='30'
                    placeholder='Name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Col>
                <Col lg={4} className='m-1'>
                  <Form.Control
                    type='text'
                    size='30'
                    placeholder='Last Name'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Col>
                <Col lg={4} className='m-1'>
                  <Form.Control
                    type='email'
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Col>
                <Col lg={4} className='m-1'>
                  <Form.Control
                    as='textarea'
                    rows='3'
                    name='notes'
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder='Notes'
                  />
                </Col>
              </Form.Row>
              <Form.Row className='justify-content-center m-2'>
                <Col lg={3} className='m-1'>
                  <Form.Label>Total:</Form.Label>
                  <CurrencyFormat
                    value={subActivityPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </Col>
              </Form.Row>
              <Form.Row className='justify-content-center m-2'>
                <Button type='submit' variant='outline-dark' size='md'>
                  Pay
                </Button>
              </Form.Row>
            </Form>
          </Col>
        </Row>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <PayPalButton
            amount={subActivityPrice}
            onSuccess={(details, data) => {
              let today = moment().format("YYYY-MM-DD")
              let arrivalNumOfPassengers = 0
              let departureNumOfPassengers = 0
              let IdArrivalService = null
              let IdDepartureService = null
              let IdArrivalTransportation = null
              let IdDepartureTransportation = null
              let IdTrip = null
              var time = moment().format("hh:mm:ss")
              let arrivalHotelName = ""
              let departureHotelName = ""
              let arrivalTransportation = ""
              let departureTransportation = ""
              /*
                Campos a insertar en la tabla activities_bookings
                -- transportationshop.activiites_bookings definition

CREATE TABLE `activiites_bookings` (
  `Id` int NOT NULL AUTO_INCREMENT,
  `Name` varchar(20) CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci DEFAULT NULL,
  `LastName` varchar(20) CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci DEFAULT NULL,
  `Email` varchar(100) CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci DEFAULT NULL,
  `FechaSubActivity` date DEFAULT NULL,
  `HoraSubActivity` time DEFAULT NULL,
  `Personas` tinyint DEFAULT '0',
  `FechaPago` date DEFAULT NULL,
  `Notas` varchar(100) CHARACTER SET utf8mb3 COLLATE utf8mb3_general_ci DEFAULT NULL,
  `FechaControl` date DEFAULT NULL,
  `Usuario` varchar(11) CHARACTER SET utf8mb3 COLLATE utf8mb3_general_ci DEFAULT NULL,
  `UsuarioModifica` varchar(11) CHARACTER SET utf8mb3 COLLATE utf8mb3_general_ci DEFAULT NULL,
  `ImporteTotalDlls` double DEFAULT '0',
  `IdActivity` int DEFAULT NULL,
  `IdSubActivity` int DEFAULT NULL,
  `PaypalOrderId` varchar(100) CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci DEFAULT NULL,
  `PaypalId` varchar(100) CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci DEFAULT NULL,
  `PaypalAmount` double DEFAULT '0',
  `PaypalCurrency_code` varchar(10) CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci DEFAULT NULL,
  `PaypalEmail_address` varchar(100) CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci DEFAULT NULL,
  `PaypalName` varchar(100) CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci DEFAULT NULL,
  `PaypalLastName` varchar(100) CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci DEFAULT NULL,
  `PaypalPayerId` varchar(100) CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci DEFAULT NULL,
  `PaypalStatus` varchar(30) DEFAULT NULL,
  `project` int NOT NULL,
  `Status` int NOT NULL DEFAULT '1',
   `Hora` time DEFAULT NULL,
  PRIMARY KEY (`Id`),
  KEY `activities_bookings_FK` (`IdActivity`),
  KEY `activiites_bookings_FK_1` (`IdSubActivity`),
  KEY `activiites_bookings_FK_2` (`project`),
  KEY `activiites_bookings_FK_3` (`Status`),
  CONSTRAINT `activiites_bookings_FK` FOREIGN KEY (`IdActivity`) REFERENCES `activities` (`_id`) ON UPDATE CASCADE,
  CONSTRAINT `activiites_bookings_FK_1` FOREIGN KEY (`IdSubActivity`) REFERENCES `sub_activities` (`id`) ON DELETE RESTRICT ON UPDATE RESTRICT,
  CONSTRAINT `activiites_bookings_FK_2` FOREIGN KEY (`project`) REFERENCES `activities_projects` (`id`) ON DELETE RESTRICT ON UPDATE CASCADE,
  CONSTRAINT `activiites_bookings_FK_3` FOREIGN KEY (`Status`) REFERENCES `Status` (`Id`) ON DELETE RESTRICT ON UPDATE RESTRICT
) ENGINE=InnoDB AUTO_INCREMENT=2 DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;
                
                */
              handleShowPaypalComplete()

              return fetch(`${server}/paypal-transaction-complete`, {
                method: "POST",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify({
                  orderID: data.orderID,
                  Name: name,
                  LastName: lastName,
                  Email: email,
                  FechaSubActivity: null,
                  HoraSubActivity: null,
                  Personas: numOfPersons,
                  FechaPago: today,
                  Notas: notes,
                  FechaControl: today,
                  Usuario: "web",
                  ImporteTotalDlls: subActivityPrice,
                  IdActivity: subActivityId,
                  PaypalAmount: details.purchase_units[0].amount.value,
                  PaypalCurrency_code:
                    details.purchase_units[0].amount.currency_code,
                  PaypalEmail_address: details.payer.email_address,
                  PaypalName: details.payer.name.given_name,
                  PaypalLastName: details.payer.name.surname,
                  PaypalPayerId: details.payer.payer_id,
                  PaypalStatus: details.status,
                  PaypalId: details.id,
                  project: 1,
                  Hora: time,
                }),
              })
            }}
            options={{
              clientId:
                "AYZm0tquYgY-AShbUdA0qsBvLsMINEHXUAkxmlRk4ij1zXWDZ5xdRfebGWUmjePKGjA7FvzdFb2KbDyA",
            }}
          />
        </Modal>

        <Modal
          backdrop='static'
          keyboard={false}
          show={showPaypalComplete}
          onHide={handleClosePaypalComplete}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Transaction Complete, Please Check Your Email...
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant='primary' onClick={handleClosePaypalComplete}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  )
}

export default SubActivityScreen
