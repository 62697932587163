import React from "react"
import { Link } from  'react-router-dom'
import { Container, Button,Jumbotron } from "react-bootstrap"
import SpecialOcassions from "../components/SpecialOcassionsCarousel"
import HeaderCarousel from "../components/HeaderCarousel";
import ReactWeather, { useVisualCrossing } from 'react-open-weather';
import Schedule from "../components/Schedule";
import Times from "../components/Times";

const HomePage = () => {

  const { data, isLoading, errorMessage } = useVisualCrossing({
    key: '92GY545HNH2R4H3F5KGGP2VDC',
    lat: '22.890533',
    lon: '-109.916740',
    lang: 'en',
    unit: 'metric', // values are (metric,us,uk)
  });

  return (
    <>
   <HeaderCarousel/>
      <Jumbotron>

        <h1>Book Online Today And Travel In Comfort On Your Next Trip</h1>

        <p>
              Allow our transfer service to exceed your expectations whether it´s a Suburbans,
            Vans, Sprinters, Escalede, Toyota SUB and Limousines. We also count with a
            hándicap Toyota SUB
            We ensure you a safe, on time and pleasant ride. We´ll hold your hand from the
            moment that you make your online transfer reservations and we will assist you to
            easily find us once you pass customs and exit the airport.
        </p>

        <p>

          <Link to="/transportations"> <Button variant="primary">Reserve</Button></Link>
          
          
        </p>

        <ReactWeather
          isLoading={isLoading}
          errorMessage={errorMessage}
          data={data}
          lang="en"
          locationLabel="Cabo San Lucas"
          unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
          showForecast
        />

      </Jumbotron> 
     
      <Container fluid="md"> 

        <SpecialOcassions/>

  </Container>
    </>
  )
}

export default HomePage
