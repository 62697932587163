import {PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    PROJECT_DETAILS_REQUEST,
    PROJECT_DETAILS_SUCCESS,
    PROJECT_DETAILS_FAIL,
    HOTEL_LIST_REQUEST,
    HOTEL_LIST_SUCCESS,
    HOTEL_LIST_FAIL,
    PRODUCT_LIST_REQUEST_PROJECTS,
    PRODUCT_LIST_FAIL_PROJECTS,
    PRODUCT_LIST_SUCCESS_PROJECTS,
    PROJECTS_INFO_REQUEST,
    PROJECTS_INFO_SUCCESS,
    PROJECTS_INFO_FAIL,
    PRODUCT_LIST_REQUEST_WEDDINGS,
    PRODUCT_LIST_FAIL_WEDDINGS,
    PRODUCT_LIST_SUCCESS_WEDDINGS,
    WEDDINGS_INFO_REQUEST,
    WEDDINGS_INFO_SUCCESS,
    WEDDINGS_INFO_FAIL,
    WEDDING_DETAILS_REQUEST,
    WEDDING_DETAILS_SUCCESS,
    WEDDING_DETAILS_FAIL,
    GROUPS_INFO_REQUEST,
    GROUPS_INFO_SUCCESS,
    GROUPS_INFO_FAIL,
    GROUP_DETAILS_REQUEST,
    GROUP_DETAILS_SUCCESS,
    GROUP_DETAILS_FAIL,
    PRODUCT_LIST_REQUEST_GROUPS,
    PRODUCT_LIST_FAIL_GROUPS,
    PRODUCT_LIST_SUCCESS_GROUPS,
    TOURS_INFO_REQUEST,
    TOURS_INFO_SUCCESS,
    TOURS_INFO_FAIL,
    TOUR_DETAILS_REQUEST,
    TOUR_DETAILS_SUCCESS,
    TOUR_DETAILS_FAIL,
    TRIPS_INFO_REQUEST,
    TRIPS_INFO_SUCCESS,
    TRIPS_INFO_FAIL,
    TRIP_DETAILS_REQUEST,
    TRIP_DETAILS_SUCCESS,
    TRIP_DETAILS_FAIL,
    HOURLY_INFO_REQUEST,
    HOURLY_INFO_SUCCESS,
    HOURLY_INFO_FAIL,
    HOURLY_DETAILS_REQUEST,
    HOURLY_DETAILS_SUCCESS,
    HOURLY_DETAILS_FAIL,
    PRODUCT_LIST_REQUEST_TOURS,
    PRODUCT_LIST_FAIL_TOURS,
    PRODUCT_LIST_SUCCESS_TOURS,
    PRODUCT_LIST_REQUEST_TRIPS,
    PRODUCT_LIST_FAIL_TRIPS,
    PRODUCT_LIST_SUCCESS_TRIPS,
    PRODUCT_LIST_REQUEST_HOURLY,
    PRODUCT_LIST_FAIL_HOURLY,
    PRODUCT_LIST_SUCCESS_HOURLY,
    ACTIVITY_LIST_REQUEST,
    ACTIVITY_LIST_SUCCESS,
    ACTIVITY_LIST_FAIL,
    SUBACTIVITIES_LIST_REQUEST,
    SUBACTIVITIES_LIST_SUCCESS,
    SUBACTIVITIES_LIST_FAIL,
    DATES_TO_EXCLUDE_LIST_REQUEST,
    DATES_TO_EXCLUDE_LIST_SUCCESS,
    DATES_TO_EXCLUDE_LIST_FAIL,
    SUBACTIVITY_DETAILS_REQUEST,
    SUBACTIVITY_DETAILS_SUCCESS,
    SUBACTIVITY_DETAILS_FAIL,
    TIME_INTERVALS_LIST_REQUEST,
    TIME_INTERVALS_LIST_SUCCESS,
    TIME_INTERVALS_LIST_FAIL,  
    } from '../constants/productConstants'

export const productListReducer=(state={products:[]},action)=>{
    switch(action.type){
        case PRODUCT_LIST_REQUEST:
            return {loading:true,products:[]}
        case PRODUCT_LIST_SUCCESS:
            return {loading:false,products: action.payload}
        case PRODUCT_LIST_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

export const productDetailsReducer=(
    state={product:{reviews:[]}},
    action
    )=>{
    switch(action.type){
        case PRODUCT_DETAILS_REQUEST:
            return {loading:true,...state}
        case PRODUCT_DETAILS_SUCCESS:
            return {loading:false,product: action.payload}
        case PRODUCT_DETAILS_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

export const hotelListReducer=(state={hotels:[]},action)=>{
    switch(action.type){
        case HOTEL_LIST_REQUEST:
            return {loading:true,hotels:[]}
        case HOTEL_LIST_SUCCESS:
            return {loading:false,hotels: action.payload}
        case HOTEL_LIST_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}



//Projects
export const productListReducerProjects=(state={productsProjects:[]},action)=>{
    switch(action.type){
        case PRODUCT_LIST_REQUEST_PROJECTS:
            return {loading:true,productsProjects:[]}
        case PRODUCT_LIST_SUCCESS_PROJECTS:
            return {loading:false,productsProjects: action.payload}
        case PRODUCT_LIST_FAIL_PROJECTS:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


export const projectListInfoReducer=(state={projectsInfo:[]},action)=>{
    switch(action.type){
        case PROJECTS_INFO_REQUEST:
            return {loading:true,projectsInfo:[]}
        case PROJECTS_INFO_SUCCESS:
            return {loading:false,projectsInfo: action.payload}
        case PROJECTS_INFO_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

export const projectDetailsInfoReducer=(
    state={project:{reviews:[]}},
    action
    )=>{
    switch(action.type){
        case PROJECT_DETAILS_REQUEST:
            return {loading:true,...state}
        case PROJECT_DETAILS_SUCCESS:
            return {loading:false,project: action.payload}
        case PROJECT_DETAILS_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


//Weddings
export const productListReducerWeddings=(state={productsWeddings:[]},action)=>{
    switch(action.type){
        case PRODUCT_LIST_REQUEST_WEDDINGS:
            return {loading:true,productsWeddings:[]}
        case PRODUCT_LIST_SUCCESS_WEDDINGS:
            return {loading:false,productsWeddings: action.payload}
        case PRODUCT_LIST_FAIL_WEDDINGS:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


export const weddingListInfoReducer=(state={weddingsInfo:[]},action)=>{
    switch(action.type){
        case WEDDINGS_INFO_REQUEST:
            return {loading:true,weddingsInfo:[]}
        case WEDDINGS_INFO_SUCCESS:
            return {loading:false,weddingsInfo: action.payload}
        case WEDDINGS_INFO_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

export const weddingDetailsInfoReducer=(
    state={wedding:{reviews:[]}},
    action
    )=>{
    switch(action.type){
        case WEDDING_DETAILS_REQUEST:
            return {loading:true,...state}
        case WEDDING_DETAILS_SUCCESS:
            return {loading:false,wedding: action.payload}
        case WEDDING_DETAILS_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

//Groups
export const productListReducerGroups=(state={productsGroups:[]},action)=>{
    switch(action.type){
        case PRODUCT_LIST_REQUEST_GROUPS:
            return {loading:true,productsGroups:[]}
        case PRODUCT_LIST_SUCCESS_GROUPS:
            return {loading:false,productsGroups: action.payload}
        case PRODUCT_LIST_FAIL_GROUPS:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


export const groupListInfoReducer=(state={groupsInfo:[]},action)=>{
    switch(action.type){
        case GROUPS_INFO_REQUEST:
            return {loading:true,groupsInfo:[]}
        case GROUPS_INFO_SUCCESS:
            return {loading:false,groupsInfo: action.payload}
        case GROUPS_INFO_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

export const groupDetailsInfoReducer=(
    state={group:{reviews:[]}},
    action
    )=>{
    switch(action.type){
        case GROUP_DETAILS_REQUEST:
            return {loading:true,...state}
        case GROUP_DETAILS_SUCCESS:
            return {loading:false,group: action.payload}
        case GROUP_DETAILS_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


//Tours
export const productListReducerTours=(state={productsTours:[]},action)=>{
    switch(action.type){
        case PRODUCT_LIST_REQUEST_TOURS:
            return {loading:true,productsTours:[]}
        case PRODUCT_LIST_SUCCESS_TOURS:
            return {loading:false,productsTours: action.payload}
        case PRODUCT_LIST_FAIL_TOURS:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


export const tourListInfoReducer=(state={toursInfo:[]},action)=>{
    switch(action.type){
        case TOURS_INFO_REQUEST:
            return {loading:true,toursInfo:[]}
        case TOURS_INFO_SUCCESS:
            return {loading:false,toursInfo: action.payload}
        case TOURS_INFO_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

export const tourDetailsInfoReducer=(
    state={tour:{reviews:[]}},
    action
    )=>{
    switch(action.type){
        case TOUR_DETAILS_REQUEST:
            return {loading:true,...state}
        case TOUR_DETAILS_SUCCESS:
            return {loading:false,tour: action.payload}
        case TOUR_DETAILS_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


//Trips
export const productListReducerTrips=(state={productsTrips:[]},action)=>{
    switch(action.type){
        case PRODUCT_LIST_REQUEST_TRIPS:
            return {loading:true,productsTrips:[]}
        case PRODUCT_LIST_SUCCESS_TRIPS:
            return {loading:false,productsTrips: action.payload}
        case PRODUCT_LIST_FAIL_TRIPS:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


export const tripListInfoReducer=(state={tripsInfo:[]},action)=>{
    switch(action.type){
        case TRIPS_INFO_REQUEST:
            return {loading:true,tripsInfo:[]}
        case TRIPS_INFO_SUCCESS:
            return {loading:false,tripsInfo: action.payload}
        case TRIPS_INFO_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

export const tripDetailsInfoReducer=(
    state={trip:{reviews:[]}},
    action
    )=>{
    switch(action.type){
        case TRIP_DETAILS_REQUEST:
            return {loading:true,...state}
        case TRIP_DETAILS_SUCCESS:
            return {loading:false,trip: action.payload}
        case TRIP_DETAILS_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

//Hourly
export const productListReducerHourly=(state={productsHourly:[]},action)=>{
    switch(action.type){
        case PRODUCT_LIST_REQUEST_HOURLY:
            return {loading:true,productsHourly:[]}
        case PRODUCT_LIST_SUCCESS_HOURLY:
            return {loading:false,productsHourly: action.payload}
        case PRODUCT_LIST_FAIL_HOURLY:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


export const hourlyListInfoReducer=(state={hourlyInfo:[]},action)=>{
    switch(action.type){
        case HOURLY_INFO_REQUEST:
            return {loading:true,hourlyInfo:[]}
        case HOURLY_INFO_SUCCESS:
            return {loading:false,hourlyInfo: action.payload}
        case HOURLY_INFO_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

export const hourlyDetailsInfoReducer=(
    state={hourly:{reviews:[]}},
    action
    )=>{
    switch(action.type){
        case HOURLY_DETAILS_REQUEST:
            return {loading:true,...state}
        case HOURLY_DETAILS_SUCCESS:
            return {loading:false,hourly: action.payload}
        case HOURLY_DETAILS_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


//Activities
export const activityListReducer=(state={productsActivity:[]},action)=>{
    switch(action.type){
        case ACTIVITY_LIST_REQUEST:
            return {loading:true,productsActivity:[]}
        case ACTIVITY_LIST_SUCCESS:
            return {loading:false,productsActivity: action.payload}
        case ACTIVITY_LIST_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

export const subactivitiesListReducer=(state={productsSubActivities:[]},action)=>{
    switch(action.type){
        case SUBACTIVITIES_LIST_REQUEST:
            return {loading:true,productsSubActivities:[]}
        case SUBACTIVITIES_LIST_SUCCESS:
            return {loading:false,productsSubActivities: action.payload}
        case SUBACTIVITIES_LIST_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

export const datestoexcludeListReducer=(state={productsDatesToExclude:[]},action)=>{
    switch(action.type){
        case DATES_TO_EXCLUDE_LIST_REQUEST:
            return {loading:true,productsDatesToExclude:[]}
        case DATES_TO_EXCLUDE_LIST_SUCCESS:
            return {loading:false,productsDatesToExclude: action.payload}
        case DATES_TO_EXCLUDE_LIST_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

export const subactivityDetailsReducer=(
    state={product:{reviews:[]}},
    action
    )=>{
    switch(action.type){
        case SUBACTIVITY_DETAILS_REQUEST:
            return {loading:true,...state}
        case SUBACTIVITY_DETAILS_SUCCESS:
            return {loading:false,product: action.payload}
        case SUBACTIVITY_DETAILS_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

export const timeintervalsListReducer=(state={productsTimeIntervals:[]},action)=>{
    switch(action.type){
        case TIME_INTERVALS_LIST_REQUEST:
            return {loading:true,productsTimeIntervals:[]}
        case TIME_INTERVALS_LIST_SUCCESS:
            return {loading:false,productsTimeIntervals: action.payload}
        case TIME_INTERVALS_LIST_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}