import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import {listTimeIntervals} from '../actions/productActions'
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import "react-datepicker/dist/react-datepicker.css";
// CSS Modules, react-datepicker-cssmodules.css
 import 'react-datepicker/dist/react-datepicker-cssmodules.css';

 
const Times = () => {
  const dispatch=useDispatch()
  const [startDate, setStartDate] = useState(new Date());
  const timeintervalsList=useSelector(state=>state.timeintervalsList)
  const {loading,error,productsTimeIntervals} =timeintervalsList

  useEffect(() => {
    dispatch(listTimeIntervals(1,1))

  }, [dispatch])
//dispatch(listTimeIntervals(props.proyect,props.subActivityId))

//console.log(`${productsTimeIntervals}/services`)  
//console.log(productsTimeIntervals[0].timeIntervals)
var timeIntervals=0;
if (productsTimeIntervals.length===0){
}
else{
  timeIntervals=productsTimeIntervals[0].timeIntervals
}
  return (
    <DatePicker
    selected={startDate}
    onChange={(date) => setStartDate(date)}
    popperPlacement="top-start"
    showTimeSelect
    showTimeSelectOnly
    timeIntervals={timeIntervals}
    timeFormat="HH:mm"
    dateFormat="hh:mm aa"
    minDate={new Date()}
    minTime={setHours(setMinutes(new Date(), 0), 8)}
    maxTime={setHours(setMinutes(new Date(), 0), 15)}
    placeholderText="Select a time"
  />

  );
};
  
  export default Times;