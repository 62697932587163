import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import {listDateToExclude} from '../actions/productActions'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// CSS Modules, react-datepicker-cssmodules.css
 import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const Schedule = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const dispatch=useDispatch()

  const datestoexcludeList=useSelector(state=>state.datestoexcludeList)

  const {loading,error,productsDatesToExclude} =datestoexcludeList

  useEffect(() => {
    dispatch(listDateToExclude(props.proyect,props.subActivityId))
  }, [dispatch,props.proyect,props.subActivityId])

  return (
    <DatePicker
    selected={startDate}
    onChange={(date) => setStartDate(date)}
    minDate={new Date()}
    excludeDateIntervals={productsDatesToExclude}
    placeholderText="Select a date"
  />    

  );
};
  
  export default Schedule;